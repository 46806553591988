import { createBrowserHistory } from 'history';
import { store } from '../store/configureStore';

export const history = createBrowserHistory();

export const copyToClipboard = (containerId) => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }

  if (document.selection) {
    const range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerId));
    range.select().createTextRange();
    document.execCommand('copy');
  } else if (window.getSelection) {
    const range = document.createRange();
    range.selectNode(document.getElementById(containerId));
    window.getSelection().addRange(range);
    document.execCommand('copy');
  }
};

export const parseAxiosErrorMessage = (error) => {
  let message = 'Something went wrong.';
  let errorObject = error;
  if (error && error.response && error.response.data) {
    errorObject = error.response.data;
  } else if (error && error.data) {
    errorObject = error.data;
  }

  if (errorObject) {
    if (typeof errorObject !== 'object' && errorObject !== null) {
      message = errorObject
    } else if (errorObject.message) {
      message = errorObject.message;
    } else if (errorObject.data) {
      message = errorObject.data;
    } else if (errorObject.data && typeof errorObject.data === 'string') {
      message = errorObject.data;
    } else if (errorObject.error && typeof errorObject.error === 'string') {
      message = errorObject.error;
    } else if (Array.isArray(errorObject?.errors) && typeof errorObject?.errors[0] === 'string') {
      message = errorObject?.errors[0];
    } else if (errorObject?.errors?.description?.[0]) {
      message = errorObject?.errors?.description?.[0];
    } else if (errorObject?.reason) {
      message = errorObject.reason;
    } else if (errorObject?.errors?.length) {
      message = errorObject.errors;
    } else if (errorObject?.[0]?.length) {
      message = errorObject?.[0];
    }
  }
  return message;
};

export const parseAxiosErrorMessageStage = (error) => {
  let message = 'Something went wrong.';
  let errorObject = error;
  if (error && error.response && error.response.data) {
    errorObject = error.response.data;
  } else if (error && error.data) {
    errorObject = error.data;
  }

  if (errorObject) {
    if (errorObject?.message) {
      message = errorObject?.message;
    } else if (Array.isArray(errorObject) && typeof errorObject[0] === 'string') {
      message = errorObject[0];
    } else if (errorObject?.errors && typeof errorObject?.errors === 'string') {
      message = errorObject?.errors;
    } else if (errorObject?.data && typeof errorObject?.data === 'string') {
      message = errorObject?.data;
    } else if (errorObject?.error && typeof errorObject?.error === 'string') {
      message = errorObject.error;
    } else if (Array.isArray(errorObject?.errors) && typeof errorObject?.errors[0] === 'string') {
      message = errorObject?.errors[0];
    } else if (errorObject?.errors?.description?.[0]) {
      message = errorObject?.errors?.description?.[0];
    }
  }
  return message;
};

export const numberDiff = (num1, num2) => Number(num1) - Number(num2);

export const getButtonCSS = (accentColor) => ({
  backgroundColor: accentColor,
  borderColor: accentColor,
  color: '#ffffff',
});

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

// REF: https://github.com/component/humanize-number/blob/master/index.js
export const humanizeNumber = (n, options) => {
  if (!n) {
    return;
  }
  options = options || {};
  const d = options.delimiter || ',';
  const s = options.separator || '.';
  n = n.toString().split('.');
  n[0] = n[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${d}`);
  return n.join(s);
};

export const prepareUserImageName = (first_name, last_name) => {
  if (!first_name && !last_name) {
    return '';
  }
  return `${first_name?.charAt(0)?.toUpperCase()}${last_name?.charAt(0)?.toUpperCase()}`;
};
export const restartSession = () => {
  const AUTH_COOKIE_NAME = 'custom-login-auth';

  const globalState = store.getState();
  const request_host = globalState?.community?.publicCommunityDetails?.request_host;
  const redirect_url = globalState?.community?.publicCommunityDetails?.redirect_url;

  localStorage.clear();
  sessionStorage.clear();
  document.cookie = `${AUTH_COOKIE_NAME}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
  window.location.href = `https://${request_host}`;
};

export function getCookie(name) {
  const cookies = `; ${document.cookie}`;
  const splitCookie = cookies.split(`; ${name}=`);
  if (splitCookie.lenght == 2) return splitCookie.pop();
}

export const getAuthToken = () => {
  try {
    const authCookie = getCookie('custom-login-auth');
    const cookieData = JSON.parse(authCookie);
    return cookieData?.token;
  } catch (error) {
    return null;
  }
};
