import {
  AUTH_LOG_OUT,
  AUTH_SET_ONBOARDED,
  AUTH_SET_REFRESH_TOKEN,
  AUTH_SET_ROOM_TOKEN,
  AUTH_SET_TOKEN,
  CHECK_EMAIL_DELETE,
  CHECK_EMAIL_REQUEST,
  GOOGLE_SIGN_IN_REQUEST,
  LOGINED_USER_EMAIL,
  LOGIN_REQUEST,
  LOGIN_USER_ID,
  ONBOARDING_COMPLETE_PROCESS_REQUEST,
  SIGN_UP_WITH_EMAIL_REQUEST,
  SET_AGORA_UID,
  MUTED_IDS,
  SET_VOICE_ROOM_TOKEN,
  SET_AGORA_UID_SHARE,
  BUBBLE_SIGN_IN_REQUEST,
  APPLE_SIGN_IN_REQUEST,
} from './actionTypes';

export const loginRequest = (data) => {
  return {
    type: LOGIN_REQUEST,
    payload: data,
  };
};

export const checkEmailRequest = (data) => {
  return {
    type: CHECK_EMAIL_REQUEST,
    payload: data,
  };
};

export const deleteCheckEmail = () => {
  return { type: CHECK_EMAIL_DELETE };
};

export const signUpWithEmail = (data) => {
  return {
    type: SIGN_UP_WITH_EMAIL_REQUEST,
    payload: data,
  };
};

export const setRoomToken = (roomToken) => {
  return {
    type: AUTH_SET_ROOM_TOKEN,
    payload: { roomToken },
  };
};

export const setOnboarded = (onboarded) => {
  const value = onboarded === 'onboarding_successful' ? true : false;

  return {
    type: AUTH_SET_ONBOARDED,
    payload: { onboarded: value },
  };
};

export const authSetToken = (token) => {
  return {
    type: AUTH_SET_TOKEN,
    payload: { token },
  };
};

export const authSetRefreshToken = (token) => {
  return {
    type: AUTH_SET_REFRESH_TOKEN,
    payload: { token },
  };
};

export const setUserId = (id) => {
  return {
    type: LOGIN_USER_ID,
    payload: id,
  };
};

export const setUserEmail = (email) => {
  return {
    type: LOGINED_USER_EMAIL,
    payload: email,
  };
};

export const completeOnboarding = (payload) => {
  return {
    type: ONBOARDING_COMPLETE_PROCESS_REQUEST,
    payload,
  };
};

export const googleSignIn = (payload) => {
  return {
    type: GOOGLE_SIGN_IN_REQUEST,
    payload,
  };
};

export const appleSignIn = (payload) => {
  return {
    type: APPLE_SIGN_IN_REQUEST,
    payload,
  };
};

export const bubbleSignIn = (payload) => {
  return {
    type: BUBBLE_SIGN_IN_REQUEST,
    payload,
  };
};

export const authLogout = () => {
  return {
    type: AUTH_LOG_OUT,
  };
};

export const setAgoraUIDShare = (agoraUID) => {
  return {
    type: SET_AGORA_UID_SHARE,
    payload: {
      agoraUID,
    },
  };
};

export const setAgoraUID = (agoraUID) => {
  return {
    type: SET_AGORA_UID,
    payload: {
      agoraUID,
    },
  };
};

export const setMutedIds = (data) => {
  return {
    type: MUTED_IDS,
    payload: data,
  };
};

export const setVoiceRoomToken = (voiceRoomToken) => {
  return {
    type: SET_VOICE_ROOM_TOKEN,
    payload: {
      voiceRoomToken,
    },
  };
};
