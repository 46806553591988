import BubbleCallback from '../views/login/BubbleCallback';
import ForgotPassword from '../views/login/ForgotPassword';
import Login from '../views/login/Login';
import ResetPassword from '../views/login/ResetPassword';
import Signup from '../views/login/Signup';

const indexRoutes = [
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/oauth2/callback',
    component: BubbleCallback,
    exact: true,
  },
  {
    path: '/signup',
    component: Signup,
    exact: true,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    exact: true,
  },
  {
    path: '/reset-password/:email/:reset_token',
    component: ResetPassword,
    exact: true,
  },
];

export default indexRoutes;
