import { combineReducers } from 'redux';
import auth from './auth';
import communityReducer from './communityReducer';
import { persistReducer } from 'redux-persist';
import { communityPersistConfig } from '../configureStore';

const createReducer = (asyncReducer) =>
  combineReducers({
    auth,
    community: persistReducer(communityPersistConfig, communityReducer),
    ...asyncReducer,
  });

export default createReducer;
