import { FaHammer } from 'react-icons/fa';
import { useState } from 'react';
import { checkForMaintenance } from '../../../services/communityService';

export default function InternalMaintenance() {
  const [isLoading, setIsLoading] = useState(false);

  const checkAgain = async () => {
    try {
      setIsLoading(true);
      try {
        const res = await checkForMaintenance();
        setIsLoading(false);
        if (!res) {
          window.location.reload(true);
        }
      } catch (error) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log('error', error);
    }
  };

  return (
    <div className="h-screen flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto">
        <div className="py-8 flex justify-center">
          <div className="text-center max-w-lg">
            {!isLoading && (
              <>
                <div className="flex justify-center">
                  <FaHammer className="h-16 w-16" color="#000" />
                </div>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  We'll be back soon!
                </h1>
                <p className="mt-2 text-base text-gray-500">
                  We are currently pushing updates.
                  {'\n'}
                  Please check back after some time
                </p>
              </>
            )}
            <button
              onClick={checkAgain}
              disabled={isLoading}
              type="submit"
              className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 rounded-md shadow-sm md:ml-3 md:w-auto md:text-sm font-semibold ${
                isLoading ? 'bg-gray-500' : 'bg-black'
              } text-white my-2 px-5`}
            >
              Check again
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}
