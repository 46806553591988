import _ from 'lodash';
import {
  FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  categories: [],
  communities: [],
  hostedCommunities: [],
  joinedCommunities: [],
  publicCommunityDetails: {},
  communityCreated: false,
  activeCommunity: null,
  posts: [],
  calendarEvents: [],
  paginationData: {},
  totalPage: 1,
  page: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    
    case FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS:
      return { ...state, publicCommunityDetails: action?.payload };

    default:
      return state;
  }
};

export default reducer;
