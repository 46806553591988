import server from '../api/server';
import serverV from '../api/serverVersionless';

export const fetchPublicCommunityDetails = async (data) => await new Promise((resolve, reject) => {
  serverV
    .get(`/verify_community?request_host=${data.host}`)
    .then((response) => {
      resolve(response?.data);
    })
    .catch(reject);
});

export const checkForMaintenance = async () => new Promise((resolve, reject) => {
  server
    .get('/maintenance')
    .then((response) => {
      if (response && response.data && response.data.maintenance_mode === true) {
        resolve(true);
      } else {
        resolve(false);
      }
    })
    .catch((e) => {
      reject(e);
    });
});
