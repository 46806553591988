import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').label('Email').required('Please enter your email'),
  password: Yup.string().label('Password').required('Please enter your password'),
});

export const continueWithEmailSchema = Yup.object().shape({
  email: Yup.string().email('Email is not valid').label('Email').required('Please enter your email'),
});

