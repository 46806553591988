import server from '../api/server';
import { API_ERROR_MESSAGES } from '../constants';

export const login = async (data) => await new Promise((resolve, reject) => {
  server
    .post(
      '/authorize',
      {
        user: {
          email: data.email,
          password: data.password,
        },
      },
      {
        params: {
          request_host: data.requestHost,
        },
      },
    )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const checkEmailExists = async (data) => await new Promise((resolve, reject) => {
  server
    .post('/login', {
      user: {
        email: data.email,
      },
    })
    .then(() => {
      resolve();
    })
    .catch((e) => {
      const errorData = e.response && e.response.data;
      // We need to check if it's email exists error or not, If it is present then we mark as success
      if (errorData && errorData.errors && errorData.errors === API_ERROR_MESSAGES.EMAIL_OR_PASSWORD_INVALID) {
        return resolve();
      }
      reject(e);
    });
});

export const registerWithEmail = async (data) => await new Promise((resolve, reject) => {
  server
    .post(
      '/sign_up',
      {
        user: {
          email: data.email,
          password: data.password,
          first_name: data.first_name,
          last_name: data.last_name,
        },
      },
      {
        params: {
          request_host: data.requestHost,
        },
      },
    )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const googleSignInRequest = async (data) => await new Promise((resolve, reject) => {
  server
    .post(
      '/users/oauth/authorize_google',
      {
        access_token: data.access_token,
      },
      {
        params: {
          request_host: data.requestHost,
        },
      },
    )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const appleSignInRequest = async (data) => await new Promise((resolve, reject) => {
  server
    .post(
      '/users/oauth/authorize_apple',
      {
        user: data?.userCode,
        identityToken: data?.identityToken,
      },
      {
        params: {
          request_host: data.requestHost,
        },
      },
    )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const bubbleSignInRequest = async (data) => await new Promise((resolve, reject) => {
  server
    .post('/users/oauth/bubble', {
      access_code: data.access_code,
      community_id: data.community_id,
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const getResetPasswordCode = (data) => new Promise((resolve, reject) => {
  server
    .post('/users/passwords', {
      email: data.email,
    }, {
      params: {
        request_host: data?.requestHost,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const resetPassword = (data) => new Promise((resolve, reject) => {
  server
    .post('/users/passwords/verify', {
      email: data.email,
      reset_password_token: data.reset_password_token, // Code from email and entered via otp boxes
      password: data.password,
      confirm_password: data.confirm_password,
    }, {
      params: {
        request_host: data?.request_host,
      },
    })
    .then(async (response) => {
      const res = response.data;
      await resetPasswordVerify(data, data?.request_host);
      resolve(res);
    })
    .catch(reject);
});

export const resetPasswordVerify = (data, request_host) => {
  console.log('data verify', data);
  return new Promise((resolve, reject) => {
    server
      .put('/users/passwords', {
        email: data.email,
        reset_password_token: data.reset_password_token, // Code from email and entered via otp boxes
        password: data.password,
        confirm_password: data.confirm_password,
      }, {
        params: {
          request_host,
        },
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });
};
