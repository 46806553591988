export const API_ERROR_MESSAGES = {
  EMAIL_OR_PASSWORD_INVALID: 'Email or password is invalid',
};

export const CHANNEL_TYPES = {
  FORUM: 'forum',
  RESOURCE: 'resources',
  STAGE: 'stage',
  CALENDER: 'calendar',
  CHAT: 'text',
  TEXT: 'text',
  BOOKMARKS: 'bookmarks',
};

export const ADDITIONAL_CHANNEL_TYPES = {
  OVERVIEW: 'overview',
};

export const ROOM_TYPES = {
  MAIN: 'main',
  OVERFLOW: 'overflow',
};

export const REPORT_TYPE = {
  FORUM_POST: 'Forum Post',
  FORUM_COMMENT: 'Forum Comment',
  FORUM_REPLY: 'Forum Reply',
  RESOURCE_COMMENT: 'Resource Comment',
  USER_REPORT: 'User Report',
};

export const ROUTE_PATH = {
  FORUM_POSTS: '/communities/:community_id/channels/:channel_id/forumposts',
  FORUM_POST_CREATE: '/communities/:community_id/channels/:channel_id/forum/create/:forum_post_id?',
  FORUM_POST_DETAILS: '/communities/:community_id/channels/:channel_id/forumposts/:forum_post_id',
  RESOURCE_POSTS: '/communities/:community_id/channels/:channel_id/resourceposts',
  RESOURCE_POST_DETAILS: '/communities/:community_id/channels/:channel_id/resourceposts/:resource_post_id',
  RESOURCE_POST_CREATE: '/communities/:community_id/channels/:channel_id/resource/create/:resource_post_id?',
  COMMUNITY_OVERVIEW_DETAILS: '/communities/:community_id',
  CALENDAR_POSTS: '/communities/:community_id/channels/:channel_id/calendarposts',
  CALENDAR_POST_DETAILS: '/communities/:community_id/channels/:channel_id/calendarposts/:event_id',
  CALENDAR_POST_CREATE: '/communities/:community_id/channels/:channel_id/calendar/create/:event_id?',
  STAGE_CHANNEL: '/communities/:community_id/channels/:channel_id/stage',
  TEXT_CHANNEL: '/communities/:community_id/channels/:channel_id/text',
  EVENT_CREATE: '/communities/:community_id/event/create',
  BOOKMARKS: '/profile/bookmarks',
};

export const FORUM_REQUEST_ORIGINS = {
  FORUM_LIST_PAGE: 'FORUM_LIST_PAGE',
  FORUM_DETAILS_PAGE: 'FORUM_DETAILS_PAGE',
  COMMUNITY_FEED_PAGE: 'COMMUNITY_FEED_PAGE',
};

export const SEE_MORE_LINES = {
  FORUM_POST: 5,
  FORUM_COMMENT: 5,
  FORUM_REPLY: 5,
  RESOURCE_FEED: 5,
  REPORT_SUMMARY: 5,
};

export const LOGIN_IFRAME_ID = 'login-ifr';

export const ONBOARD_IFRAME_ID = 'onboard-ifr';
