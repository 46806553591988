/** AUTH ACTIONS **/
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAILED = 'CHECK_EMAIL_FAILED';
export const CHECK_EMAIL_DELETE = 'CHECK_EMAIL_DELETE';

export const SIGN_UP_WITH_EMAIL_REQUEST = 'SIGN_UP_WITH_EMAIL_REQUEST';
export const SIGN_UP_WITH_EMAIL_SUCCESS = 'SIGN_UP_WITH_EMAIL_SUCCESS';
export const SIGN_UP_WITH_EMAIL_FAILED = 'SIGN_UP_WITH_EMAIL_FAILED';

export const EMAIL_RESEND_OTP_REQUEST = 'EMAIL_RESEND_OTP_REQUEST';
export const EMAIL_RESEND_OTP_SUCCESS = 'EMAIL_RESEND_OTP_SUCCESS';
export const EMAIL_RESEND_OTP_FAILED = 'EMAIL_RESEND_OTP_FAILED';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_SET_REFRESH_TOKEN = 'AUTH_SET_REFRESH_TOKEN';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
export const AUTH_SET_ROOM_TOKEN = 'AUTH_SET_ROOM_TOKEN';
export const AUTH_DELETE_ROOM_TOKEN = 'AUTH_DELETE_ROOM_TOKEN';
export const FETCHING_PROFILE_DATA = 'FETCHING_PROFILE_DATA';
export const AUTH_SET_ONBOARDED = 'AUTH_SET_ONBOARDED';
export const AUTH_DELETE_ONBOARDED = 'AUTH_DELETE_ONBOARDED';
export const LOGIN_USER_ID = 'LOGIN_USER_ID';
export const LOGINED_USER_EMAIL = 'LOGINED_USER_EMAIL';

export const SET_USER = 'SET_USER';

export const GOOGLE_SIGN_IN_REQUEST = 'GOOGLE_SIGN_IN_REQUEST';
export const GOOGLE_SIGN_IN_SUCCESS = 'GOOGLE_SIGN_IN_SUCCESS';
export const GOOGLE_SIGN_IN_FAILED = 'GOOGLE_SIGN_IN_FAILED';

export const BUBBLE_SIGN_IN_REQUEST = 'BUBBLE_SIGN_IN_REQUEST';
export const BUBBLE_SIGN_IN_SUCCESS = 'BUBBLE_SIGN_IN_SUCCESS';
export const BUBBLE_SIGN_IN_FAILED = 'BUBBLE_SIGN_IN_FAILED';

export const APPLE_SIGN_IN_REQUEST = 'APPLE_SIGN_IN_REQUEST';

/** ONBOARDING ACTIONS **/

export const ONBOARDING_COMPLETE_PROCESS_REQUEST = 'ONBOARDING_COMPLETE_PROCESS_REQUEST';

/** COMMUNITY ACTIONS **/
export const FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST = 'FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST';
export const FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS = 'FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS';

export const SET_AGORA_UID = 'SET_AGORA_UID';
export const SET_AGORA_UID_SHARE = 'SET_AGORA_UID_SHARE';
export const MUTED_IDS = 'MUTED_IDS';
export const SET_VOICE_ROOM_TOKEN = 'SET_VOICE_ROOM_TOKEN';