import { LockClosedIcon } from '@heroicons/react/solid';
import { ErrorMessage, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import AppleSignin from 'react-apple-signin-auth';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthBaseLayout from '../../components/auth/BaseLayout';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import CommunityNotFound from '../../components/shared/tailwind/CommunityNotFound';
import LoadingSpinner from '../../components/shared/tailwind/LoadingSpinner';
import { signupSchema } from '../../schema/registerSchema';
import { getRequestHostFromCommunityPublicDetails } from '../../selectors/CommunitySelectors';
import { fetchPublicCommunityDetails } from '../../services/communityService';
import { imageFetch } from '../../services/preSignedAws';
import { FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS } from '../../store/actions/actionTypes';
import { authLogout, googleSignIn, signUpWithEmail } from '../../store/actions/authActions';
import useWindowSize from '../../hooks/useWindowSize';

const DEFAULT_COMMUNITY_ICON = 'https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg';
const DEFAULT_LOGIN_COVER =
  'https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80';

export default function Signup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const appearanceData = useSelector((state) => state?.community?.publicCommunityDetails);
  const authToken = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [isDomainVerified, setIsDomainVerified] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState('');

  const primaryColor = appearanceData?.appearance_metadata?.primary_color || '#6366F1';
  const urlParams = new URLSearchParams(window.location.search);
  const request_host = urlParams.get('request_host');
  const redirect_url = urlParams.get('redirect_url');
  const requestHostFromPublicDetails = useSelector(getRequestHostFromCommunityPublicDetails);
  const { width, height } = useWindowSize();

  const fetchPublicDetails = useCallback(async () => {
    try {
      const response = await fetchPublicCommunityDetails({ host: request_host });
      dispatch({
        type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
        payload: { ...response?.data, request_host, redirect_url },
      });
      setIsDomainVerified(true);
      setIsLoading(false);
      setPrivacyPolicy(response?.data?.settings?.privacy_policy);
    } catch (error) {
      console.error(error);
      setIsDomainVerified(false);
      setIsLoading(false);
    }
  }, [request_host, redirect_url]);

  useEffect(() => {
    if (requestHostFromPublicDetails && requestHostFromPublicDetails !== request_host) {
      dispatch(authLogout());
    }
    if (request_host) {
      fetchPublicDetails();
    } else {
      setIsDomainVerified(false);
      setIsLoading(false);
    }
  }, []);

  const responseGoogle = (response) => {
    dispatch(googleSignIn({ access_token: response.tokenId, requestHost: request_host, redirectUrl: redirect_url }));
  };

  const failureGoogle = (error) => {
    console.error(error);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return isDomainVerified ? (
    <AuthBaseLayout>
      <div className="min-h-full flex main--background" style={{ height: `${height}px` }}>
        <div className="flex-1 flex justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 overflow-scroll">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto m-auto rounded-lg"
                src={appearanceData?.icon ? imageFetch(appearanceData?.icon) : DEFAULT_COMMUNITY_ICON}
                alt="Workflow"
              />
              <h2 className="mt-6 text-3xl font-extrabold text__title text-center">
                Sign up to {appearanceData?.name ? appearanceData?.name : 'your account'}
              </h2>
            </div>

            <div className="mt-8">
              <div className="social__login">
                <div>
                  <p className="text-sm font-medium text__title">Sign up with</p>

                  <div className="mt-1 grid grid-cols-2 gap-2">
                    <div>
                      <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        render={(renderProps) => (
                          <a
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            className="w-full inline-flex justify-center py-2 btn__light rounded-md shadow-sm text-sm font-medium cursor-pointer"
                          >
                            <span className="align-middle inline-block mr-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                              >
                                <path d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032 s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2 C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
                              </svg>
                            </span>
                            <span className="font-semibold align-text-bottom inline-block">Google</span>
                          </a>
                        )}
                        cookiePolicy="single_host_origin"
                        onSuccess={responseGoogle}
                        onFailure={failureGoogle}
                        accessType="offline"
                        prompt="consent"
                        fetchBasicProfile={false}
                        autoLoad={false}
                      />
                    </div>

                    <div className="signinwith__apple__gg">
                      <AppleSignin
                        authOptions={{
                          clientId: 'com.avalonmeta.scenes',
                          scope: 'email name',
                          redirectURI: window.location.origin,
                          state: 'state',
                          nonce: 'nonce',
                          usePopup: true,
                        }}
                        onSuccess={() => {}}
                        onError={(error) => console.error(error)}
                        render={(props) => (
                          <a
                            {...props}
                            className="w-full inline-flex justify-center items-center py-2 btn__light rounded-md shadow-sm text-sm font-medium cursor-pointer"
                          >
                            <span className="align-middle inline-block mr-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="20"
                                height="20"
                                viewBox="0 0 50 50"
                                fill="currentColor"
                              >
                                <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z" />
                              </svg>
                            </span>
                            <span className="font-semibold align-middle inline-block">Apple</span>
                          </a>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center">
                  <div className="flex-1 border-t divider" />
                  <span className="px-2 text__description">Or continue with</span>
                  <div className="flex-1 border-t divider" />
                </div>
              </div>

              <div className="mt-6">
                <Formik
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    email: '',
                    password: '',
                    accept_conditions: false,
                  }}
                  onSubmit={(values, actions) => {
                    dispatch(signUpWithEmail({ ...values, requestHost: request_host, redirectUrl: redirect_url }));
                    actions.setSubmitting(false);
                  }}
                  validationSchema={signupSchema}
                  enableReinitialize
                >
                  {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                      {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                      <div>
                        <label htmlFor="first_name" className="block text-sm font-medium text__title">
                          First name
                        </label>
                        <div className="mt-1">
                          <input
                            id="first_name"
                            name="first_name"
                            type="text"
                            required
                            className="appearance-none rounded-md relative block w-full px-3 py-2 border input card rounded-b-md focus:outline-none focus:z-10 sm:text-sm"
                            onChange={handleChange}
                            value={values.first_name}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="first_name" render={CustomErrorMessage} />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="last_name" className="block text-sm font-medium text__title">
                          Last name
                        </label>
                        <div className="mt-1">
                          <input
                            id="last_name"
                            name="last_name"
                            type="text"
                            required
                            className="appearance-none rounded-md relative block w-full px-3 py-2 border input card rounded-b-md focus:outline-none focus:z-10 sm:text-sm"
                            onChange={handleChange}
                            value={values.last_name}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="last_name" render={CustomErrorMessage} />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text__title">
                          Email address
                        </label>
                        <div className="mt-1">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none rounded-md relative block w-full px-3 py-2 border input card rounded-b-md focus:outline-none focus:z-10 sm:text-sm"
                            onChange={handleChange}
                            value={values.email}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="email" render={CustomErrorMessage} />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="password" className="block text-sm font-medium text__title">
                          Password
                        </label>
                        <div className="mt-1">
                          <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none rounded-md relative block w-full px-3 py-2 border input card rounded-b-md focus:outline-none focus:z-10 sm:text-sm"
                            onChange={handleChange}
                            value={values.password}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="password" render={CustomErrorMessage} />
                        </div>
                      </div>

                      <div className="flex items-center justify-start">
                        <div className="flex items-center">
                          <input
                            id="accept_conditions"
                            name="accept_conditions"
                            type="checkbox"
                            required
                            className="h-4 w-4 text-gray-400 focus:ring-offset-0 focus:ring-0 focus:ring-gray-300 border-gray-300  rounded text__link"
                            checked={values.accept_conditions}
                            value={values.accept_conditions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{ color: primaryColor }}
                          />
                          <label htmlFor="accept_conditions" className="ml-2 block text-sm text__title mt-1">
                            I accept the terms of service & privacy policy
                          </label>
                          <ErrorMessage name="accept_conditions" render={CustomErrorMessage} />
                        </div>
                        <a
                          className="ml-2 text-sm cursor-pointer font-semibold text__link hover:underline mt-1"
                          style={{ color: primaryColor }}
                          href={privacyPolicy ? privacyPolicy : 'https://www.buildonscenes.com/privacy-policy'}
                          target="_blank"
                          rel="noreferrer"
                        >
                          See Here
                        </a>
                      </div>

                      <div>
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          style={{ backgroundColor: primaryColor }}
                          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 bg-indigo-600 btn__primary"
                        >
                          <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <LockClosedIcon
                              className="h-5 w-5 hidden"
                              style={{ color: primaryColor }}
                              aria-hidden="true"
                            />
                          </span>
                          Sign up
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>

            <div className="mt-6 relative">
              <div className="relative flex justify-center text-sm">
                <span className="px-2 text__description">
                  Have an account already?
                  <span
                    className="ml-1 cursor-pointer font-semibold text__link"
                    style={{ color: primaryColor }}
                    onClick={() => {
                      history.push(`/login?request_host=${request_host}&redirect_url=${redirect_url || ''}`);
                    }}
                  >
                    Signin
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={
              appearanceData?.appearance_metadata?.login_cover_image
                ? imageFetch(appearanceData?.appearance_metadata?.login_cover_image)
                : DEFAULT_LOGIN_COVER
            }
            alt=""
          />
        </div>
      </div>
    </AuthBaseLayout>
  ) : (
    <CommunityNotFound />
  );
}
