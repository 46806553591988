import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/Geomanist-Regular.otf';
import './main.scss';
import './index.css';
import CommunityNotFound from './components/shared/tailwind/CommunityNotFound';
import InternalMaintenance from './components/shared/tailwind/InternalMaintenance';
import { checkForMaintenance } from './services/communityService';
import indexRoutes from './routes';

function RootComponent() {
  const [page, setPage] = useState(null);
  const init = async () => {
    try {
      const res = await checkForMaintenance();
      if (res) {
        setPage('maintenance');
      } else if (indexRoutes.map((el) => el.path).includes(location.pathname)) {
        setPage(null);
      } else {
        setPage('error');
      }
    } catch (error) {
      setPage(null);
    }
  };
  useEffect(() => {
    init();
  }, []);

  if (page === 'maintenance') {
    return <InternalMaintenance />;
  }
  if (!page) {
    return <App setPage={setPage} />;
  }
  return <CommunityNotFound />;
}

ReactDOM.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
