import { ErrorMessage, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { XIcon } from '@heroicons/react/solid';
import AuthBaseLayout from '../../components/auth/BaseLayout';
import { CustomErrorMessage } from '../../components/shared/ErrorMessage';
import { LOGIN_IFRAME_ID } from '../../constants';
import { parseAxiosErrorMessage, restartSession } from '../../constants/utils';
import { getResetPasswordCode } from '../../services/authServices';
import NotificationService from '../../services/notificationService';
import { imageFetch } from '../../services/preSignedAws';
import { FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS } from '../../store/actions/actionTypes';
import { fetchPublicCommunityDetails } from '../../services/communityService';
import { createIframe } from './loginUtils';
import { getRequestHostFromCommunityPublicDetails } from '../../selectors/CommunitySelectors';
import useWindowSize from '../../hooks/useWindowSize';

const DEFAULT_COMMUNITY_ICON = 'https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg';
const DEFAULT_LOGIN_COVER =
  'https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80';

function AskForEmailVerification(props) {
  const { width, height } = useWindowSize();

  return (
    <div className="min-h-full flex main--background" style={{ height: `${height}px` }}>
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text__title text-center">Forgot Password?</h2>
          </div>

          <div className="mt-8">
            <div>
              <div className="mt-6 relative">
                <div className="relative flex justify-center text-sm text-center">
                  <span className="px-2 text__description">
                    Enter your email we'll send you a code to get back into your account.
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <Formik
                initialValues={{ email: '' }}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(true);
                  getResetPasswordCode({ email: values?.email || '', requestHost: props?.requestHostFromPublicDetails })
                    .then((res) => {
                      actions.setSubmitting(false);
                      props.setCurrentEmail(values?.email || '');
                      NotificationService.success('Code sent successfully');
                    })
                    .catch((error) => {
                      if (error?.response?.data?.errors === 'Invalid email provided') {
                        NotificationService.error('Email id not registered');
                      } else {
                        const message = parseAxiosErrorMessage(error);
                        NotificationService.error(message);
                      }
                      actions.setSubmitting(false);
                    });
                }}
                //   validationSchema={signupSchema}
                enableReinitialize
              >
                {({ values, handleChange, handleBlur, setFieldValue, handleSubmit, isSubmitting }) => (
                  <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    {/* <input type="hidden" name="remember" defaultValue="true" /> */}
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text__title">
                        Enter your email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          required
                          className="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm input card"
                          onChange={handleChange}
                          value={values.email}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="email" render={CustomErrorMessage} />
                      </div>
                    </div>

                    <div>
                      <button
                        style={{ background: props.primaryColor }}
                        disabled={isSubmitting}
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-semibold rounded-md bg-indigo-600 btn__primary"
                      >
                        Send Code
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={
            props.publicCommunityDetails?.appearance_metadata?.login_cover_image
              ? imageFetch(props.publicCommunityDetails?.appearance_metadata?.login_cover_image)
              : DEFAULT_LOGIN_COVER
          }
          alt=""
        />
      </div>
      {/* <div className="fixed inset-y-0 right-5 top-5 pl-10 max-w-full flex">
        <div className="ml-3 h-7 flex items-center">
          <button
            onClick={restartSession}
            type="button"
            style={{ background: props.primaryColor }}
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="sr-only">Close panel</span>
            <XIcon
              className="h-5 w-5 text-white group-hover:text-gray-200"
              aria-hidden="true"
            />
          </button>
        </div>
      </div> */}
    </div>
  );
}

function VerifyEmailOTP(props) {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const request_host = urlParams.get('request_host');
  const redirect_url = urlParams.get('redirect_url');
  const { width, height } = useWindowSize();

  function handleResendOtp() {
    getResetPasswordCode({ email: props?.currentEmail || '', requestHost: props?.requestHostFromPublicDetails })
      .then(() => {
        NotificationService.success('Code sent successfully');
      })
      .catch((error) => {
        const message = parseAxiosErrorMessage(error);
        NotificationService.error(message);
      });
  }

  return (
    <div className="min-h-full flex main--background" style={{ height: `${height}px` }}>
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text__title text-center">Forgot Password?</h2>
          </div>

          <div className="mt-8">
            <div>
              <div className="mt-6 relative">
                <div className="relative flex justify-center text-sm text-center">
                  <span className="px-2 text__description">
                    Enter your email we'll send you a code to get back into your account.
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-6">
              <Formik
                initialValues={{ email: '', otp: '' }}
                onSubmit={(values, actions) => {
                  history.replace(
                    `/reset-password/${props.currentEmail}/${values.otp}?request_host=${
                      request_host || ''
                    }&redirect_url=${redirect_url || ''}`,
                  );
                  actions.setSubmitting(false);
                }}
                //   validationSchema={signupSchema}
                enableReinitialize
              >
                {({ values, handleChange, handleBlur, setFieldValue, handleSubmit, isSubmitting }) => (
                  <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
                    {/* <input type="hidden" name="remember" defaultValue="true" /> */}

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text__title">
                        Enter your email
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          className="appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm input card"
                          value={props.currentEmail}
                          disabled
                        />
                        <ErrorMessage name="email" render={CustomErrorMessage} />
                      </div>
                    </div>

                    <div>
                      <OtpInput
                        containerStyle={{ display: 'flex', justifyContent: 'space-between' }}
                        // inputStyle={{
                        //   minWidth: '3.5rem',
                        //   borderRadius: '0.375rem',
                        //   background: 'rgba(249, 250, 251)',
                        //   borderColor: 'rgba(209, 213, 219)',
                        // }}
                        inputStyle="input card rounded-md min-w-3"
                        value={values.otp}
                        onChange={setFieldValue.bind(this, 'otp')}
                        key="otp"
                        name="otp"
                        numInputs={6}
                        placeholder="******"
                        separator={<span>&nbsp;</span>}
                      />
                    </div>

                    <div>
                      <button
                        style={{ background: props.primaryColor }}
                        disabled={isSubmitting}
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md bg-indigo-600 btn__primary"
                      >
                        Verify Code
                      </button>
                      <div className="flex justify-end items-center mt-4">
                        <div onClick={handleResendOtp} className="text-sm text__link cursor-pointer">
                          Resend Code
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={
            props.publicCommunityDetails?.appearance_metadata?.login_cover_image
              ? imageFetch(props.publicCommunityDetails?.appearance_metadata?.login_cover_image)
              : DEFAULT_LOGIN_COVER
          }
          alt=""
        />
      </div>
      {/* <div className="fixed inset-y-0 right-5 top-5 pl-10 max-w-full flex">
        <div className="ml-3 h-7 flex items-center">
          <button
            onClick={restartSession}
            type="button"
            style={{ background: props.primaryColor }}
            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span className="sr-only">Close panel</span>
            <XIcon
              className="h-5 w-5 text-white group-hover:text-gray-200"
              aria-hidden="true"
            />
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default function ForgotPassword() {
  const [currentEmail, setCurrentEmail] = useState('');
  const dispatch = useDispatch();
  const publicCommunityDetails = useSelector((state) => state?.community?.publicCommunityDetails);

  const primaryColor = publicCommunityDetails?.appearance_metadata?.primary_color || '#6366F1';
  const urlParams = new URLSearchParams(window.location.search);
  const request_host = urlParams.get('request_host');
  const redirect_url = urlParams.get('redirect_url');

  const requestHostFromPublicDetails = useSelector(getRequestHostFromCommunityPublicDetails);

  useEffect( () => {
    if (request_host) {
      getPublicCommunity(request_host);
    }
  }, []);

  const getPublicCommunity = async (host) => {
    const response = await fetchPublicCommunityDetails({ host: host });
    dispatch({
      type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
      payload: { ...response?.data, request_host, redirect_url },
    });
    createIframe(LOGIN_IFRAME_ID, `https://${request_host}/getlocalstorage.html`);
  };

  return (
    <AuthBaseLayout>
      {currentEmail ? (
        <VerifyEmailOTP
          currentEmail={currentEmail}
          setCurrentEmail={setCurrentEmail}
          primaryColor={primaryColor}
          publicCommunityDetails={publicCommunityDetails}
          requestHostFromPublicDetails={requestHostFromPublicDetails}
        />
      ) : (
        <AskForEmailVerification
          setCurrentEmail={setCurrentEmail}
          primaryColor={primaryColor}
          publicCommunityDetails={publicCommunityDetails}
          requestHostFromPublicDetails={requestHostFromPublicDetails}
        />
      )}
    </AuthBaseLayout>
  );
}
