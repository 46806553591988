import mixpanel from 'mixpanel-browser';

class AnalyticsService {
  constructor() {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
    this.trackUrlChange();
  }

  trackUrlChange = ()=>{
    let oldHref = document.location.href;

    window.onload = function () {
      AnalyticsService.logMixpanelEvent('Web Navigation', {
        'Current Page': document.location.href,
        'Previous Page': oldHref == document.location.href ? "": oldHref,
      });

      const bodyList = document.querySelector('body');
      const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (oldHref != document.location.href) {
            AnalyticsService.logMixpanelEvent('Web Navigation', {
              'Current Page': document.location.href,
              'Previous Page': oldHref,
            });
          }
          oldHref = document.location.href;
        });
      });

      const config = {
        childList: true,
        subtree: true,
      };

      observer.observe(bodyList, config);
    };
};

  static setMixpanelIdentify = (userId) => {
    if (userId && mixpanel) {
      mixpanel.identify(userId.toString());
    }
  };

  static logMixpanelEvent = (eventName = '', params = {}) => {
    console.log(eventName,params)
    if (mixpanel) {
      mixpanel.track(eventName, params);
    }
  };

  static setUser = () => {};

  static setUserAttributes = (data) => {
    if (data.first_name) {
      if (mixpanel) {
        mixpanel.people.set('$first_name', data.first_name);
        mixpanel.people.set('$name', `${data.first_name} ${data.last_name}`);
        mixpanel.people.set('First Name', data.first_name);
      }
    }

    if (data.last_name) {
      if (mixpanel) {
        mixpanel.people.set('$last_name', data.last_name);
        mixpanel.people.set('Last Name', data.last_name);
      }
    }

    if (data.email) {
      if (mixpanel) {
        mixpanel.people.set('$email', data.email);
        mixpanel.people.set('Email', data.email);
      }
    }

    if (data.phone_number) {
      if (mixpanel) {
        mixpanel.people.set('$phone', data.phone_number);
        mixpanel.people.set('Phone Number', data.phone_number);
      }
    }

    if (data.otp_verified) {
      if (mixpanel) {
        mixpanel.people.set('Otp verified', data.otp_verified);
      }
    }

    if (data.gender) {
      if (mixpanel) {
        mixpanel.people.set('Gender', data.gender);
      }
    }

    if (data.dob) {
      if (mixpanel) {
        mixpanel.people.set('DOB', data.dob);
      }
    }

    if (data.age) {
      if (mixpanel) {
        mixpanel.people.set('Age', data.age);
      }
    }

    if (data.personal_referral_code) {
      if (mixpanel) {
        mixpanel.people.set('Personal Referral Code', data.personal_referral_code);
      }
    }

    if (data.invited_via_referral_code) {
      if (mixpanel) {
        mixpanel.people.set('Invited Via Referral Code', data.invited_via_referral_code);
      }
    }

    if (data.voice_onboarding_status === 'voice_onboarding_successful') {
      if (mixpanel) {
        mixpanel.people.set('Onboarding Status', 'Onboarded');
      }
    }

    if (data.voice_onboarding_status === 'voice_onboarding_completed') {
      if (mixpanel) {
        mixpanel.people.set('Onboarding Completed', true);
      }
    }

    if (data.communities_joined) {
      if (mixpanel) {
        mixpanel.people.set('Communities Joined', data.communities_joined);
      }
    }

    if (data.has_community) {
      if (mixpanel) {
        mixpanel.people.set('Has Community', data.has_community);
      }
    }
  };

  static clearUser = () => {
    if (mixpanel) {
      mixpanel.reset();
    }
  };
}

export default AnalyticsService;
